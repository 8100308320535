<template>
  <div class="analytics">
    <div v-if="analytics" class="analytics-table" style="position: relative">
      <div class="table">
        <b-table
          id="my-table"
          class="table__box"
          :borderless="true"
          :items="analyticsList"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          small
        >
          <template #cell(region_name)="data">
            <div class="table-name">
              {{ data.item.region_name }}
            </div>
          </template>
          <template #cell(status)="data">
            <div class="working__road flex">
              <p class="working__road-text working__road_begin">KRG</p>
              <div class="working__road-block">
                <div class="working__road-box" :style="`width: ${data.item.status};`" />
                <div class="working__road-box-after" />
              </div>
              <p class="working__road-text">TSE</p>
            </div>
          </template>

          <!--            {{ code.slice(0, 10 - $route.params.id.toString().length) }}{{ $route.params.id }}-->
        </b-table>
      </div>
      <div class="table-pagination analytics-pagination flex">
        <p class="analytics-pagination__text analytics-pagination__text-default">
          {{ currentPage }}-{{ perPage }} из {{ analytics.length }}
        </p>
        <div class="analytics-pagination__box flex">
          <p class="analytics-pagination__text">Показать сразу</p>
          <div class="analytics-pagination__select-block">
            <b-form-select
              v-model="swowItem"
              class="analytics-pagination__select main__select"
              :options="options"
              @change="poginationShow()"
            />
            <img class="main__select-icon" src="@/assets/png/select-active.png" alt="" />
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            :hide-goto-end-buttons="true"
            :limit="1"
            class="anslytic-pagination"
          >
            <template #prev-text>
              <img
                class="analytics-pagination__btn-icon analytics-pagination__btn-icon_prev"
                src="@/assets/svg/path-left.svg"
                alt=""
              />
            </template>
            <template #next-text>
              <img class="analytics-pagination__btn-icon" src="@/assets/svg/path.svg" alt="" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
    <b-spinner v-else class="spinner" label="Large Spinner" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      swowItem: null,
      perPage: 10,
      currentPage: 1,
      code: "0000000000",
      options: [
        { value: null, text: "10" },
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "3", text: "4", disabled: true },
      ],
      fields: [
        {
          key: "region_name",
          label: "Список направлении",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "point_counter_agent",
          label: "Кол-во клиентов (контрагент)",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "point_count",
          label: "Кол-во выездов",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
          // variant: 'danger'
        },
        {
          key: "distance",
          label: "Общий пройденный километраж, км",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "total_expense",
          label: "Общий расход по ГСМ, л",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "total_expense_price",
          label: "Общий расход по ГСМ, в тенге",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        // {
        //   key: 'departure',
        //   label: 'Выезд из',
        //   sortable: true,
        //   isRowHeader: true,
        //   class: "tb-all",
        // },
        // {
        //   key: 'location',
        //   label: 'Местоположение',
        //   sortable: true,
        //   isRowHeader: true,
        //   class: "tb-all",
        // }
      ],
    };
  },
  computed: {
    ...mapState(["analytics"]),
    rows() {
      return this.analytics.length;
    },
    analyticsList() {
      const keys = Object.keys(this.analytics);
      const result = keys.map((key) => this.analytics[key]);
      return [
        ...result,
        {
          region_name: "Итог",
          point_counter_agent: result.reduce((a, b) => a + +b.point_counter_agent, 0),
          point_count: result.reduce((a, b) => a + +b.point_count, 0),
          distance: result.reduce((a, b) => a + +b.distance, 0),
          total_expense: result.reduce((a, b) => a + +b.total_expense, 0),
          total_expense_price: result.reduce((a, b) => a + +b.total_expense_price, 0),
        },
      ];
    },
  },
  mounted() {
    if(!this.analytics)
      this.$store.dispatch("getAnalytics");
  },
  methods: {
    poginationShow() {
      this.perPage = this.swowItem;
    },
  },
};
</script>

<style scoped>
.table {
  width: 100%;
}

.table-name {
  width: 100%;
  background: transparent;
  text-align: start;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--secondary-color);
  position: relative;
  padding: 0 30px;
}

.table-name p {
  font-size: 10px;
  line-height: 12px;
  color: #acacac;
}

.table-box {
  width: 25px;
  position: absolute;
  top: -5px;
  right: -5px;
}

/* --- */
.table-pagination {
  margin: 15px 0;
  padding: 15px 5px 15px 15px;
  background: #e0e9fa;
  border-radius: 15px;
}

.analytics-pagination__text {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #306cdc;
  color: #707070;
}

.analytics-pagination__text-default {
  color: var(--secondary-color);
}

.analytics-pagination__box {
  width: 300px;
  height: 30px;
}

.analytics-pagination__text-inner {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  color: #707070;
}

.analytics-pagination__select-block {
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  color: #707070;
  width: 60px;
  margin: 0 25px 0 10px;
  color: #83b1f4;
}

.analytics-pagination__select {
  border: 1px solid #83b1f4;
  color: #83b1f4;
}

.main__select-icon {
  right: 8px;
}

.page-item {
  margin-right: 10px;
  border: 1px solid #83b1f4;
  border-radius: 5px;
}

.page-item:nth-child(2) {
  display: none;
}

.analytics-pagination__btn-icon_prev {
  transform: rotate(180deg);
}

.working__road {
  margin: 0;
}

.spinner {
  top: 50%;
}

.anslytic-pagination {
  position: inherit;
}

.table-box img {
  width: 12px;
}
</style>
